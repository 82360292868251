<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <!--vandar login-->
            <b-modal
                    ref="vandar-modal"
                    title="ورود به وندار"
                    ok-title="تایید پرداخت"
                    cancel-title="لغو"
                    v-model="vandarModal"
                    @ok.prevent="doVandarPaymennt"
            >
                <b-form>
                    <b-form-group class="mt-1" label="نام کاربری حساب وندار">
                        <b-form-input v-model="vandar.mobile" placeholder="نام کاربری"/>
                    </b-form-group>
                    <b-form-group class="mt-1" label="رمز عبور حساب وندار">
                        <b-form-input v-model="vandar.password" placeholder="رمز عبور" type="password"/>
                    </b-form-group>
                </b-form>
            </b-modal>

            <!--toman transaction manual-->
            <b-modal
                    ref="toman-modal"
                    title="پرداخت دستی"
                    ok-title="تایید پرداخت"
                    cancel-title="دارای نقص"
                    v-model="tomanModal"
                    @ok.prevent="acceptTomanDeposit(label)"
                    @cancel.prevent="conflictTomanDeposit(label)"
            >

                <b-overlay :show="loading"
                           rounded="sm">
                    <b-form label="">
                        <p v-if="label.payment_method === 'ONLINE'">
                            مقدار <span class="text-primary">{{ label.amount ? label.amount : '' }}</span> تومان
                            از کارت
                            <span class="text-primary">{{ label.account ? label.account.card_number : '' }}</span>
                            با شناسه پرداخت <span class="text-primary">{{
                                label.payment ? label.payment.token : '-'
                            }}</span>
                            واریز شده است. با مراجعه به پنل درگاه بانکی خود نصبت به تایید یا عدم تایید آن اقدام نمایید.
                        </p>
                        <p v-else>
                            مقدار <span class="text-primary">{{ label.amount }}</span> تومان از شماره
                            {{ label.payment_method === 'card' ? 'حساب' : 'کارت' }}

                            <span class="text-primary">{{
                                    label.payment_method === 'card' ? (label.account ? label.account.card_number : '') : (label.account ? label.account.account_number : '')
                                }}</span>
                            واریز شده است. با مقایسه عکس زیر نصبت به تایید یا عدم تایید آن اقدام نمایید.
                        </p>
                    </b-form>
                    <div>
                        <img class="receipt" v-if="label" :src="label.payment.image" alt="">
                    </div>
                </b-overlay>
            </b-modal>

            <!--crypto deposit-->
            <b-modal
                    ref="crypto-modal"
                    title="پرداخت رمزارز"
                    ok-title="تایید پرداخت"
                    cancel-title="لغو در خواست"
                    cancel-variant="danger"
                    v-model="cryptoModal"
                    @ok.prevent="manualAcceptCrypto()"
                    @cancel="cancelRequest(label)"
            >
                <b-form style="min-height: 250px" @click.stop.prevent="cryptoModal=true">
                    مقدار
                    <span class="text-primary">{{ label.amount ? label.amount : 0 }}</span>
                    با شناسه تراکنش (tx id)
                    <span>{{ label.payment ? label.payment.tx_id : '' }}</span>
                    به حساب شما واریز شده است. پس از بررسی و افزودن مقدار دقیق واریزی نسبت به تایید یا عدم تایید آن
                    اقدام نمایید.

                    <b-form-group class="mt-1" label="مقدار ارز واریزی">
                        <b-form-input v-model="amount" placeholder="مقدار"/>
                    </b-form-group>

                </b-form>
            </b-modal>

            <div class="m-1 d-flex justify-content-between"
                 dir="ltr"
            >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="downloadExcel">
                    <!--                    <feather-icon icon="FilterIcon"/>-->
                    <img width="15" src="../../assets/images/excel.png" alt="" class="mr-50"
                         style="filter: invert(100%)"/>
                    خروجی اکسل درخواست های واریز
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >
                    <template #cell(email)="data">
                        <span dir="ltr">
                    {{ data.item.user.email }}
                        </span>
                    </template>

                    <template #cell(created_at)="data">
                        <span dir="ltr">
                    {{ $G2J(data.item.created_at, 'faTime') }}
                        </span>
                    </template>

                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.amount) }}
                        </span>
                    </template>

                    <template #cell(paymentMethod)="data">
                        <span dir="ltr">
                            {{ paymentMethodLabel(data.item.payment_method) }}
                        </span>
                    </template>

                    <template #cell(destination)="data">
                        <span dir="ltr">
                            {{
                                data.item.account ? data.item.account.account_number : data.item.payment ? data.item.payment.address : ''
                            }}
                        </span>
                    </template>

                    <template #cell(coin)="data">
                        <b-avatar
                                size="25"
                                class="mr-50"
                                :src="data.item.coin.logo"
                                variant="light-info"
                        />
                        {{ data.item.coin.label }}
                    </template>

                    <template #cell(action)="{item}">
                        <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DELETE_ORDERS')">
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="$havePermission('CREATE_WALLET')"
                                                 @click="item.coin.code === 'TOMAN'?
                                     selectTomanPaymentModal(item) :
                                      selectCryptoPaymentModal(item)"
                                >
                                    <!--                                    selectPaymentModal([$toLocal(item.amount),item.account ? item.account.sheba : '',item.id])-->
                                    <feather-icon class="text-success" icon="CheckSquareIcon"/>
                                    <span class="text-success align-middle ml-50">انجام تراکنش</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="cancelRequest(item)">
                                    <feather-icon class="text-danger" icon="XCircleIcon"/>
                                    <span class="text-danger align-middle ml-50">لغو درخواست</span>
                                </b-dropdown-item>

                                <!--                            <b-dropdown-item>-->
                                <!--                                <feather-icon icon="FileTextIcon"/>-->
                                <!--                                <span class="align-middle ml-50">جزییات سفارش</span>-->
                                <!--                            </b-dropdown-item>-->
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>

                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    // BBadge,
    BAvatar,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    // BListGroup,
    // BListGroupItem,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import NotAllowed from "@/layouts/components/NotAllowed";

export default {
    name: 'Deposit',
    components: {
        NotAllowed,
        SearchAndFilter,
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        // BBadge,
        BAvatar,
        vSelect,
        BForm,
        BFormInput,
        BFormGroup,
        BOverlay,
        // BListGroup,
        // BListGroupItem,

    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 20,
        sortBy: '',
        isSortDirDesc: '',
        userData: [],
        label: '',
        transactionHash: '',
        tomanModal: false,
        vandarModal: false,
        cryptoModal: false,
        isActive: false,
        amount: '',
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        vandar: {
            mobile: '',
            password: ''
        },
        columns: [
            {
                label: 'نام کاربر',
                key: 'email',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'روش واریز',
                key: 'paymentMethod',
                sortable: true,
            },
            {
                label: 'مقدار درخواستی',
                key: 'amount',
                sortable: true,
                searchType: 'number'
            },
            {
                label: 'واحد درخواست',
                key: 'coin',
                sortable: false,
                searchType: 'text',
            },
            {
                label: 'حساب مقصد',
                key: 'destination',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'تاریخ درخواست',
                key: 'created_at',
                sortable: false,
                searchType: 'date'
            },
            {
                label: 'عملیات',
                key: 'action'
            },
        ],
        loading: false,
    }),
    computed: {
        statusVariant() {
            const a = {
                inactive: 'danger',
                active: 'success',
            }
            return e => a[e];
        },
        statusLabel() {
            const a = {
                inactive: 'لغو شده',
                active: 'انجام شده',
            }
            return e => a[e];
        },
        paymentMethodLabel() {
            const a = {
                TRANSFER: 'انتقال',
                ONLINE: 'آنلاین',
                CARD: 'کارت به کارت',
                CRYPTO: 'ارزی',
                REFERRAL: 'ارجاع'
            }
            return (e) => a[e]
        }
    },
    methods: {
        selectTomanPaymentModal(item) {
            this.label = item
            this.tomanModal = true
        },
        selectPaymentModal(e) {
            this.label = e
            this.boxTwo = ''
            this.$bvModal
                .msgBoxConfirm('لطفا طریقه انجام تراکنش را انتخاب کنید.', {
                    title: 'شیوه پرداخت',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'درگاه بانکی',
                    cancelTitle: 'پرداخت دستی',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        this.doVandarPaymennt()
                    } else if (value === false) {
                        this.tomanModal = true
                    }
                })
        },
        selectCryptoPaymentModal(e) {
            this.label = e
            this.$bvModal
                .msgBoxConfirm('لطفا طریقه انجام تراکنش را انتخاب کنید.', {
                    title: 'شیوه پرداخت',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'بررسی اتوماتیک',
                    cancelTitle: 'بررسی دستی',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        this.autoCheckCryptoPayment()
                    } else if (value === false) {
                        this.cryptoModal = true
                    }
                })
        },
        async acceptTomanDeposit(e) {
            if (!this.loading) {
                this.loading = true
                await this.$axios.post('/transactions/' + e.id + '/accept')
                this.$swal({
                    icon: 'success',
                    title: `تراکنش ثبت شد`,
                    confirmButtonText: 'تایید',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })
                this.loading = false
                this.tomanModal = false
                this.getData()
            }
        },

        async conflictTomanDeposit(e) {
            if (!this.loading) {
                this.loading = true
                await this.$axios.post('/transactions/' + e.id + '/conflict')
                this.$swal({
                    icon: 'success',
                    title: `تراکنش دارای نقص ثبت شد`,
                    confirmButtonText: 'تایید',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })
                this.loading = false
                this.tomanModal = false
                this.getData()
            }
        },

        async autoCheckCryptoPayment() {
            this.state.loading = true
            await this.$axios.patch('/transactions/' + this.label.id + '/check-deposit')
            this.$error('پرداخت با موفقیت انجام شد', '', 'success')
            this.label = {}
            this.getData(this.currentPage, this.perPage)
        },

        async manualAcceptCrypto() {

            this.$swal({
                icon: 'success',
                title: "آیا از ثبت درخواست واریز کاربر مطمئن هستید؟",
                confirmButtonText: 'ثبت درخواست',
                showCancelButton: true,
                cancelButtonText: 'خیر',
                text: "مشخصات درخواست : کاربر " + this.label.user.profile.name + " واریز مقدار " + this.$toLocal(this.label.amount) + " " + this.label.coin.label,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-primary ml-1',
                },
            }).then(async (result) => {
                if (result.isConfirmed) {

                    await this.$axios.post('/transactions/' + this.label.id + '/accept', {
                        amount: this.amount
                    })


                    this.$error('پرداخت با موفقیت انجام شد', '', 'success')
                    this.label = {}
                    this.amount = ''
                    this.cryptoModal = false
                    this.getData(this.currentPage, this.perPage)
                }
                })


        },

        async downloadExcel() {
            await this.$axios(
                {
                    url: '/transactions',
                    method: 'GET',
                    responseType: 'blob', // important
                    params: {
                        export: 'excel',
                        type: 'deposit',
                        status: 'pending',
                        ...this.$route.query
                    }
                }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        },


        cancelRequest(e) {
            this.label = e
            this.$swal({
                icon: 'error',
                title: "آیا از لغو درخواست کاربر مطمئن هستید؟",
                confirmButtonText: 'تایید و لغو درخواست',
                showCancelButton: true,
                cancelButtonText: 'خیر',
                text: "مشخصات درخواست : کاربر " + this.label.user.profile.name + " مقدار " + this.$toLocal(this.label.amount) + " " + this.label.coin.label,
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-primary ml-1',
                },
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.state.loading = true
                    const address = '/transactions/' + this.label.id + '/reject'
                    await this.$axios.post(address)
                    this.label = {}
                    await this.getData(this.currentPage, this.perPage)

                    this.$swal({
                        icon: 'success',
                        title: 'سفارش لغو شد',
                        confirmButtonText: 'تایید',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },

        sort(e) {
            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            })

            this.getData(1, this.perPage)

        },

        async getData(page = 1, perPage = this.perPage) {
            this.state.loading = true

            const queryParams = {
                size: perPage,
                page: page,
                type: 'deposit',
                status: 'pending',
                ...this.$route.query
            }
            const res = await this.$axios('/transactions', {params: queryParams})

            this.state.loading = false
            this.items = res.data.data
            this.currentPage = res.data.meta.current_page
            this.rows = res.data.meta.total
        },
    },
    created() {
        this.getData()
    }
}
</script>
<style lang="scss">
.vs__selected {
  width: calc(100% - 20px);
}

[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}

.receipt {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
}
</style>
